import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An ISO 8601-encoded datetime */
  DateTime: { input: string; output: string; }
  /** Represents untyped JSON */
  JSON: { input: any; output: any; }
};

/** Autogenerated return type of AttachAvatar. */
export type AttachAvatarPayload = {
  readonly __typename?: 'AttachAvatarPayload';
  readonly status: Status;
};

export type Attached = {
  readonly __typename?: 'Attached';
  readonly filename: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly key: Scalars['String']['output'];
};

export type Authentication = {
  readonly __typename?: 'Authentication';
  readonly deleted: Scalars['Boolean']['output'];
  readonly geography?: Maybe<Geography>;
  readonly id: Scalars['ID']['output'];
  readonly ip: Scalars['String']['output'];
  readonly me: Scalars['Boolean']['output'];
  readonly seen: Scalars['DateTime']['output'];
  readonly status: AuthenticationStatusEnum;
  readonly user: User;
};

export enum AuthenticationStatusEnum {
  Offline = 'OFFLINE',
  Online = 'ONLINE'
}

export type Billing = {
  readonly __typename?: 'Billing';
  readonly customer?: Maybe<Billing__Customer>;
  readonly id: Scalars['ID']['output'];
};

export enum Billing__Currency {
  Cad = 'CAD',
  Eur = 'EUR',
  Usd = 'USD'
}

export type Billing__Customer = {
  readonly __typename?: 'Billing__Customer';
  readonly currency?: Maybe<Billing__Currency>;
  readonly id: Scalars['ID']['output'];
  readonly sources: ReadonlyArray<Billing__Source>;
};

export type Billing__Source = {
  readonly __typename?: 'Billing__Source';
  readonly brand: Billing__Source__Brand;
  readonly default: Scalars['Boolean']['output'];
  readonly exp: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly number: Scalars['String']['output'];
};

export enum Billing__Source__Brand {
  AmericanExpress = 'AMERICAN_EXPRESS',
  DinersClub = 'DINERS_CLUB',
  Discover = 'DISCOVER',
  Jcb = 'JCB',
  Mastercard = 'MASTERCARD',
  Unionpay = 'UNIONPAY',
  Unknown = 'UNKNOWN',
  Visa = 'VISA'
}

/** Autogenerated return type of BuildBillingSource. */
export type BuildBillingSourcePayload = {
  readonly __typename?: 'BuildBillingSourcePayload';
  readonly billing: Billing;
};

/** Autogenerated return type of BuildFeedComment. */
export type BuildFeedCommentPayload = {
  readonly __typename?: 'BuildFeedCommentPayload';
  readonly comment?: Maybe<Feed__Comment>;
  readonly errors?: Maybe<Errors>;
  readonly status: Status;
};

/** Autogenerated return type of ChangePassword. */
export type ChangePasswordPayload = {
  readonly __typename?: 'ChangePasswordPayload';
  readonly errors?: Maybe<Errors>;
  readonly status: Status;
};

/** Autogenerated return type of ChangeProfile. */
export type ChangeProfilePayload = {
  readonly __typename?: 'ChangeProfilePayload';
  readonly errors?: Maybe<Errors>;
  readonly status: Status;
};

/** Autogenerated return type of DefaultBillingSource. */
export type DefaultBillingSourcePayload = {
  readonly __typename?: 'DefaultBillingSourcePayload';
  readonly billing: Billing;
};

/** Autogenerated return type of DestroyAuthentication. */
export type DestroyAuthenticationPayload = {
  readonly __typename?: 'DestroyAuthenticationPayload';
  readonly authentication: Authentication;
};

/** Autogenerated return type of DestroyBillingSource. */
export type DestroyBillingSourcePayload = {
  readonly __typename?: 'DestroyBillingSourcePayload';
  readonly billing: Billing;
};

/** Autogenerated return type of DestroyNotification. */
export type DestroyNotificationPayload = {
  readonly __typename?: 'DestroyNotificationPayload';
  readonly notification: Notification;
};

/** Autogenerated return type of DetachAvatar. */
export type DetachAvatarPayload = {
  readonly __typename?: 'DetachAvatarPayload';
  readonly status: Status;
};

export type Errors = {
  readonly __typename?: 'Errors';
  readonly messages: Scalars['JSON']['output'];
};

export type Feed = {
  readonly __typename?: 'Feed';
  readonly entries: ReadonlyArray<Feed__Entry>;
  readonly entry: Feed__Entry;
  readonly id: Scalars['ID']['output'];
};


export type FeedEntryArgs = {
  id: Scalars['ID']['input'];
};

export type Feed__Comment = {
  readonly __typename?: 'Feed__Comment';
  readonly id: Scalars['ID']['output'];
  readonly message: Scalars['String']['output'];
  readonly sent: Scalars['DateTime']['output'];
  readonly user: User;
};

export type Feed__CommentInput = {
  readonly entryID: Scalars['ID']['input'];
  readonly message: Scalars['String']['input'];
};

export type Feed__Entry = {
  readonly __typename?: 'Feed__Entry';
  readonly comments: ReadonlyArray<Feed__Comment>;
  readonly id: Scalars['ID']['output'];
  readonly liked: Scalars['Boolean']['output'];
  readonly likes: Scalars['Int']['output'];
  readonly photos: ReadonlyArray<Attached>;
  readonly tags: ReadonlyArray<Scalars['String']['output']>;
  readonly user: User;
};

/** Autogenerated return type of FollowUser. */
export type FollowUserPayload = {
  readonly __typename?: 'FollowUserPayload';
  readonly user: User;
};

export type Geography = {
  readonly __typename?: 'Geography';
  readonly city: Scalars['String']['output'];
  readonly continent: Scalars['String']['output'];
  readonly country: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly latitude: Scalars['Float']['output'];
  readonly longitude: Scalars['Float']['output'];
  readonly postal: Scalars['String']['output'];
  readonly region: Scalars['String']['output'];
};

/** Autogenerated return type of LikeFeedEntry. */
export type LikeFeedEntryPayload = {
  readonly __typename?: 'LikeFeedEntryPayload';
  readonly entry: Feed__Entry;
};

export type LoginInput = {
  readonly email: Scalars['String']['input'];
  readonly password: Scalars['String']['input'];
};

/** Autogenerated return type of LoginMutation. */
export type LoginMutationPayload = {
  readonly __typename?: 'LoginMutationPayload';
  readonly errors?: Maybe<Errors>;
  readonly status: Status;
  readonly token?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of LogoutMutation. */
export type LogoutMutationPayload = {
  readonly __typename?: 'LogoutMutationPayload';
  readonly status: Status;
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly attachAvatar: AttachAvatarPayload;
  readonly buildBillingSource: BuildBillingSourcePayload;
  readonly buildFeedComment: BuildFeedCommentPayload;
  readonly changePassword: ChangePasswordPayload;
  readonly changeProfile: ChangeProfilePayload;
  readonly defaultBillingSource: DefaultBillingSourcePayload;
  readonly destroyAuthentication: DestroyAuthenticationPayload;
  readonly destroyBillingSource: DestroyBillingSourcePayload;
  readonly destroyNotification: DestroyNotificationPayload;
  readonly detachAvatar: DetachAvatarPayload;
  readonly followUser: FollowUserPayload;
  readonly likeFeedEntry: LikeFeedEntryPayload;
  readonly login: LoginMutationPayload;
  readonly logout: LogoutMutationPayload;
  readonly readNotification: ReadNotificationPayload;
  readonly signup: SignupMutationPayload;
  readonly unfollowUser: UnfollowUserPayload;
  readonly unlikeFeedEntry: UnlikeFeedEntryPayload;
};


export type MutationAttachAvatarArgs = {
  id: Scalars['ID']['input'];
};


export type MutationBuildBillingSourceArgs = {
  source: Scalars['String']['input'];
};


export type MutationBuildFeedCommentArgs = {
  input: Feed__CommentInput;
};


export type MutationChangePasswordArgs = {
  password: PasswordInput;
};


export type MutationChangeProfileArgs = {
  input: UserInput;
};


export type MutationDefaultBillingSourceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyAuthenticationArgs = {
  id: Scalars['String']['input'];
};


export type MutationDestroyBillingSourceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationFollowUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLikeFeedEntryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationReadNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSignupArgs = {
  input: SignupInput;
};


export type MutationUnfollowUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnlikeFeedEntryArgs = {
  id: Scalars['ID']['input'];
};

export type Notification = {
  readonly __typename?: 'Notification';
  readonly deleted: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  readonly message: Scalars['String']['output'];
  readonly read: Scalars['Boolean']['output'];
  readonly sent: Scalars['DateTime']['output'];
};

export type PasswordInput = {
  readonly current: Scalars['String']['input'];
  readonly replacement: Scalars['String']['input'];
};

export type Query = {
  readonly __typename?: 'Query';
  readonly authentications: ReadonlyArray<Authentication>;
  readonly billing: Billing;
  readonly feed: Feed;
  readonly me?: Maybe<User>;
  readonly notifications: ReadonlyArray<Notification>;
  readonly user: User;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ReadNotification. */
export type ReadNotificationPayload = {
  readonly __typename?: 'ReadNotificationPayload';
  readonly notification: Notification;
};

export type SignupInput = {
  readonly email: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
  readonly password: Scalars['String']['input'];
};

/** Autogenerated return type of SignupMutation. */
export type SignupMutationPayload = {
  readonly __typename?: 'SignupMutationPayload';
  readonly errors?: Maybe<Errors>;
  readonly status: Status;
  readonly token?: Maybe<Scalars['String']['output']>;
};

export enum Status {
  Ok = 'OK',
  Unprocessable = 'UNPROCESSABLE'
}

/** Autogenerated return type of UnfollowUser. */
export type UnfollowUserPayload = {
  readonly __typename?: 'UnfollowUserPayload';
  readonly user: User;
};

/** Autogenerated return type of UnlikeFeedEntry. */
export type UnlikeFeedEntryPayload = {
  readonly __typename?: 'UnlikeFeedEntryPayload';
  readonly entry: Feed__Entry;
};

export type User = {
  readonly __typename?: 'User';
  readonly avatar?: Maybe<Attached>;
  readonly email: Scalars['String']['output'];
  readonly feed: Feed;
  readonly followed: Scalars['Int']['output'];
  readonly follower: Scalars['Int']['output'];
  readonly following: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
};

export type UserInput = {
  readonly email: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
};

export type AttachedFragment = { readonly __typename?: 'Attached', readonly id: string, readonly key: string, readonly filename: string };

export type AuthenticationFragment = { readonly __typename?: 'Authentication', readonly id: string, readonly ip: string, readonly deleted: boolean, readonly me: boolean, readonly seen: string, readonly status: AuthenticationStatusEnum, readonly geography?: { readonly __typename?: 'Geography', readonly id: string, readonly city: string, readonly region: string, readonly country: string, readonly postal: string, readonly latitude: number, readonly longitude: number } | null };

export type Billing__SourceFragment = { readonly __typename?: 'Billing__Source', readonly id: string, readonly number: string, readonly brand: Billing__Source__Brand, readonly exp: string, readonly default: boolean };

export type Feed__CommentFragment = { readonly __typename?: 'Feed__Comment', readonly id: string, readonly message: string, readonly sent: string, readonly user: { readonly __typename?: 'User', readonly id: string, readonly name: string, readonly avatar?: { readonly __typename?: 'Attached', readonly id: string, readonly key: string, readonly filename: string } | null } };

export type Feed__EntryFragment = { readonly __typename?: 'Feed__Entry', readonly id: string, readonly tags: ReadonlyArray<string>, readonly liked: boolean, readonly likes: number, readonly photos: ReadonlyArray<{ readonly __typename?: 'Attached', readonly id: string, readonly key: string, readonly filename: string }>, readonly user: { readonly __typename?: 'User', readonly id: string, readonly name: string, readonly avatar?: { readonly __typename?: 'Attached', readonly id: string, readonly key: string, readonly filename: string } | null } };

export type FollowFragment = { readonly __typename?: 'User', readonly following: boolean, readonly followed: number, readonly follower: number };

export type GeographyFragment = { readonly __typename?: 'Geography', readonly id: string, readonly city: string, readonly region: string, readonly country: string, readonly postal: string, readonly latitude: number, readonly longitude: number };

export type NotificationFragment = { readonly __typename?: 'Notification', readonly id: string, readonly message: string, readonly deleted: boolean, readonly read: boolean, readonly sent: string };

export type UserFragment = { readonly __typename?: 'User', readonly id: string, readonly name: string, readonly avatar?: { readonly __typename?: 'Attached', readonly id: string, readonly key: string, readonly filename: string } | null };

export type BuildFeedCommentMutationVariables = Exact<{
  input: Feed__CommentInput;
}>;


export type BuildFeedCommentMutation = { readonly __typename?: 'Mutation', readonly result: { readonly __typename?: 'BuildFeedCommentPayload', readonly status: Status, readonly errors?: { readonly __typename?: 'Errors', readonly messages: any } | null, readonly comment?: { readonly __typename?: 'Feed__Comment', readonly id: string, readonly message: string, readonly sent: string, readonly user: { readonly __typename?: 'User', readonly id: string, readonly name: string, readonly avatar?: { readonly __typename?: 'Attached', readonly id: string, readonly key: string, readonly filename: string } | null } } | null } };

export type SettingsBillingSourceDestroyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SettingsBillingSourceDestroyMutation = { readonly __typename?: 'Mutation', readonly result: { readonly __typename?: 'DestroyBillingSourcePayload', readonly billing: { readonly __typename?: 'Billing', readonly id: string, readonly customer?: { readonly __typename?: 'Billing__Customer', readonly id: string, readonly sources: ReadonlyArray<{ readonly __typename?: 'Billing__Source', readonly id: string, readonly number: string, readonly brand: Billing__Source__Brand, readonly exp: string, readonly default: boolean }> } | null } } };

export type DestroyNotificationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DestroyNotificationMutation = { readonly __typename?: 'Mutation', readonly result: { readonly __typename?: 'DestroyNotificationPayload', readonly notification: { readonly __typename?: 'Notification', readonly id: string, readonly deleted: boolean } } };

export type FollowUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type FollowUserMutation = { readonly __typename?: 'Mutation', readonly result: { readonly __typename?: 'FollowUserPayload', readonly user: { readonly __typename?: 'User', readonly id: string, readonly following: boolean, readonly followed: number, readonly follower: number } } };

export type LikeFeedEntryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type LikeFeedEntryMutation = { readonly __typename?: 'Mutation', readonly result: { readonly __typename?: 'LikeFeedEntryPayload', readonly entry: { readonly __typename?: 'Feed__Entry', readonly id: string, readonly liked: boolean, readonly likes: number } } };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { readonly __typename?: 'Mutation', readonly login: { readonly __typename?: 'LoginMutationPayload', readonly status: Status, readonly token?: string | null, readonly errors?: { readonly __typename?: 'Errors', readonly messages: any } | null } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { readonly __typename?: 'Mutation', readonly logout: { readonly __typename?: 'LogoutMutationPayload', readonly status: Status } };

export type ReadNotificationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ReadNotificationMutation = { readonly __typename?: 'Mutation', readonly result: { readonly __typename?: 'ReadNotificationPayload', readonly notification: { readonly __typename?: 'Notification', readonly id: string, readonly read: boolean } } };

export type SettingsAuthenticationDestroyMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type SettingsAuthenticationDestroyMutation = { readonly __typename?: 'Mutation', readonly result: { readonly __typename?: 'DestroyAuthenticationPayload', readonly authentication: { readonly __typename?: 'Authentication', readonly id: string, readonly deleted: boolean } } };

export type SettingsAvatarAttachMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SettingsAvatarAttachMutation = { readonly __typename?: 'Mutation', readonly result: { readonly __typename?: 'AttachAvatarPayload', readonly status: Status } };

export type SettingsAvatarDetachMutationVariables = Exact<{ [key: string]: never; }>;


export type SettingsAvatarDetachMutation = { readonly __typename?: 'Mutation', readonly result: { readonly __typename?: 'DetachAvatarPayload', readonly status: Status } };

export type SettingsBillingSourceBuildMutationVariables = Exact<{
  source: Scalars['String']['input'];
}>;


export type SettingsBillingSourceBuildMutation = { readonly __typename?: 'Mutation', readonly result: { readonly __typename?: 'BuildBillingSourcePayload', readonly billing: { readonly __typename?: 'Billing', readonly id: string, readonly customer?: { readonly __typename?: 'Billing__Customer', readonly id: string, readonly sources: ReadonlyArray<{ readonly __typename?: 'Billing__Source', readonly id: string, readonly number: string, readonly brand: Billing__Source__Brand, readonly exp: string, readonly default: boolean }> } | null } } };

export type SettingsBillingSourceDefaultMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SettingsBillingSourceDefaultMutation = { readonly __typename?: 'Mutation', readonly result: { readonly __typename?: 'DefaultBillingSourcePayload', readonly billing: { readonly __typename?: 'Billing', readonly id: string, readonly customer?: { readonly __typename?: 'Billing__Customer', readonly id: string, readonly sources: ReadonlyArray<{ readonly __typename?: 'Billing__Source', readonly id: string, readonly number: string, readonly brand: Billing__Source__Brand, readonly exp: string, readonly default: boolean }> } | null } } };

export type SettingsChangeProfileMutationVariables = Exact<{
  input: UserInput;
}>;


export type SettingsChangeProfileMutation = { readonly __typename?: 'Mutation', readonly result: { readonly __typename?: 'ChangeProfilePayload', readonly status: Status, readonly errors?: { readonly __typename?: 'Errors', readonly messages: any } | null } };

export type SettingsPasswordMutationVariables = Exact<{
  password: PasswordInput;
}>;


export type SettingsPasswordMutation = { readonly __typename?: 'Mutation', readonly result: { readonly __typename?: 'ChangePasswordPayload', readonly status: Status, readonly errors?: { readonly __typename?: 'Errors', readonly messages: any } | null } };

export type SignupMutationVariables = Exact<{
  input: SignupInput;
}>;


export type SignupMutation = { readonly __typename?: 'Mutation', readonly signup: { readonly __typename?: 'SignupMutationPayload', readonly status: Status, readonly token?: string | null, readonly errors?: { readonly __typename?: 'Errors', readonly messages: any } | null } };

export type UnfollowUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UnfollowUserMutation = { readonly __typename?: 'Mutation', readonly result: { readonly __typename?: 'UnfollowUserPayload', readonly user: { readonly __typename?: 'User', readonly id: string, readonly following: boolean, readonly followed: number, readonly follower: number } } };

export type UnlikeFeedEntryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UnlikeFeedEntryMutation = { readonly __typename?: 'Mutation', readonly result: { readonly __typename?: 'UnlikeFeedEntryPayload', readonly entry: { readonly __typename?: 'Feed__Entry', readonly id: string, readonly liked: boolean, readonly likes: number } } };

export type FeedQueryVariables = Exact<{ [key: string]: never; }>;


export type FeedQuery = { readonly __typename?: 'Query', readonly feed: { readonly __typename?: 'Feed', readonly id: string, readonly entries: ReadonlyArray<{ readonly __typename?: 'Feed__Entry', readonly id: string, readonly tags: ReadonlyArray<string>, readonly liked: boolean, readonly likes: number, readonly photos: ReadonlyArray<{ readonly __typename?: 'Attached', readonly id: string, readonly key: string, readonly filename: string }>, readonly user: { readonly __typename?: 'User', readonly id: string, readonly name: string, readonly avatar?: { readonly __typename?: 'Attached', readonly id: string, readonly key: string, readonly filename: string } | null } }> } };

export type FeedCommentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type FeedCommentsQuery = { readonly __typename?: 'Query', readonly feed: { readonly __typename?: 'Feed', readonly id: string, readonly entry: { readonly __typename?: 'Feed__Entry', readonly id: string, readonly comments: ReadonlyArray<{ readonly __typename?: 'Feed__Comment', readonly id: string, readonly message: string, readonly sent: string, readonly user: { readonly __typename?: 'User', readonly id: string, readonly name: string, readonly avatar?: { readonly __typename?: 'Attached', readonly id: string, readonly key: string, readonly filename: string } | null } }> } } };

export type FeedEntryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type FeedEntryQuery = { readonly __typename?: 'Query', readonly feed: { readonly __typename?: 'Feed', readonly id: string, readonly entry: { readonly __typename?: 'Feed__Entry', readonly id: string, readonly tags: ReadonlyArray<string>, readonly liked: boolean, readonly likes: number, readonly photos: ReadonlyArray<{ readonly __typename?: 'Attached', readonly id: string, readonly key: string, readonly filename: string }>, readonly user: { readonly __typename?: 'User', readonly id: string, readonly name: string, readonly avatar?: { readonly __typename?: 'Attached', readonly id: string, readonly key: string, readonly filename: string } | null } } } };

export type NotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationsQuery = { readonly __typename?: 'Query', readonly notifications: ReadonlyArray<{ readonly __typename?: 'Notification', readonly id: string, readonly message: string, readonly deleted: boolean, readonly read: boolean, readonly sent: string }> };

export type ProfileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ProfileQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly name: string, readonly following: boolean, readonly followed: number, readonly follower: number, readonly feed: { readonly __typename?: 'Feed', readonly entries: ReadonlyArray<{ readonly __typename?: 'Feed__Entry', readonly id: string, readonly tags: ReadonlyArray<string>, readonly liked: boolean, readonly likes: number, readonly photos: ReadonlyArray<{ readonly __typename?: 'Attached', readonly id: string, readonly key: string, readonly filename: string }>, readonly user: { readonly __typename?: 'User', readonly id: string, readonly name: string, readonly avatar?: { readonly __typename?: 'Attached', readonly id: string, readonly key: string, readonly filename: string } | null } }> }, readonly avatar?: { readonly __typename?: 'Attached', readonly id: string, readonly key: string, readonly filename: string } | null } };

export type SettingsAuthenticationsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsAuthenticationsQuery = { readonly __typename?: 'Query', readonly authentications: ReadonlyArray<{ readonly __typename?: 'Authentication', readonly id: string, readonly ip: string, readonly deleted: boolean, readonly me: boolean, readonly seen: string, readonly status: AuthenticationStatusEnum, readonly geography?: { readonly __typename?: 'Geography', readonly id: string, readonly city: string, readonly region: string, readonly country: string, readonly postal: string, readonly latitude: number, readonly longitude: number } | null }> };

export type SettingsAvatarQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsAvatarQuery = { readonly __typename?: 'Query', readonly me?: { readonly __typename?: 'User', readonly id: string, readonly name: string, readonly avatar?: { readonly __typename?: 'Attached', readonly id: string, readonly key: string, readonly filename: string } | null } | null };

export type SettingsBillingQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsBillingQuery = { readonly __typename?: 'Query', readonly billing: { readonly __typename?: 'Billing', readonly id: string, readonly customer?: { readonly __typename?: 'Billing__Customer', readonly id: string, readonly currency?: Billing__Currency | null, readonly sources: ReadonlyArray<{ readonly __typename?: 'Billing__Source', readonly id: string, readonly number: string, readonly brand: Billing__Source__Brand, readonly exp: string, readonly default: boolean }> } | null } };

export type SettingsProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsProfileQuery = { readonly __typename?: 'Query', readonly me?: { readonly __typename?: 'User', readonly id: string, readonly name: string, readonly email: string } | null };

export const GeographyFragmentDoc = gql`
    fragment geography on Geography {
  id
  city
  region
  country
  postal
  latitude
  longitude
}
    `;
export const AuthenticationFragmentDoc = gql`
    fragment authentication on Authentication {
  id
  ip
  deleted
  me
  seen
  status
  geography {
    ...geography
  }
}
    ${GeographyFragmentDoc}`;
export const Billing__SourceFragmentDoc = gql`
    fragment billing__source on Billing__Source {
  id
  number
  brand
  exp
  default
}
    `;
export const AttachedFragmentDoc = gql`
    fragment attached on Attached {
  id
  key
  filename
}
    `;
export const UserFragmentDoc = gql`
    fragment user on User {
  id
  name
  avatar {
    ...attached
  }
}
    ${AttachedFragmentDoc}`;
export const Feed__CommentFragmentDoc = gql`
    fragment feed__comment on Feed__Comment {
  id
  message
  sent
  user {
    ...user
  }
}
    ${UserFragmentDoc}`;
export const Feed__EntryFragmentDoc = gql`
    fragment feed__entry on Feed__Entry {
  id
  tags
  liked
  likes
  photos {
    ...attached
  }
  user {
    ...user
  }
}
    ${AttachedFragmentDoc}
${UserFragmentDoc}`;
export const FollowFragmentDoc = gql`
    fragment follow on User {
  following
  followed
  follower
}
    `;
export const NotificationFragmentDoc = gql`
    fragment notification on Notification {
  id
  message
  deleted
  read
  sent
}
    `;
export const BuildFeedCommentDocument = gql`
    mutation BuildFeedComment($input: Feed__CommentInput!) {
  result: buildFeedComment(input: $input) {
    status
    errors {
      messages
    }
    comment {
      ...feed__comment
    }
  }
}
    ${Feed__CommentFragmentDoc}`;
export type BuildFeedCommentMutationFn = Apollo.MutationFunction<BuildFeedCommentMutation, BuildFeedCommentMutationVariables>;

/**
 * __useBuildFeedCommentMutation__
 *
 * To run a mutation, you first call `useBuildFeedCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildFeedCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildFeedCommentMutation, { data, loading, error }] = useBuildFeedCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildFeedCommentMutation(baseOptions?: Apollo.MutationHookOptions<BuildFeedCommentMutation, BuildFeedCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildFeedCommentMutation, BuildFeedCommentMutationVariables>(BuildFeedCommentDocument, options);
      }
export type BuildFeedCommentMutationHookResult = ReturnType<typeof useBuildFeedCommentMutation>;
export type BuildFeedCommentMutationResult = Apollo.MutationResult<BuildFeedCommentMutation>;
export type BuildFeedCommentMutationOptions = Apollo.BaseMutationOptions<BuildFeedCommentMutation, BuildFeedCommentMutationVariables>;
export const SettingsBillingSourceDestroyDocument = gql`
    mutation SettingsBillingSourceDestroy($id: ID!) {
  result: destroyBillingSource(id: $id) {
    billing {
      id
      customer {
        id
        sources {
          ...billing__source
        }
      }
    }
  }
}
    ${Billing__SourceFragmentDoc}`;
export type SettingsBillingSourceDestroyMutationFn = Apollo.MutationFunction<SettingsBillingSourceDestroyMutation, SettingsBillingSourceDestroyMutationVariables>;

/**
 * __useSettingsBillingSourceDestroyMutation__
 *
 * To run a mutation, you first call `useSettingsBillingSourceDestroyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsBillingSourceDestroyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsBillingSourceDestroyMutation, { data, loading, error }] = useSettingsBillingSourceDestroyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSettingsBillingSourceDestroyMutation(baseOptions?: Apollo.MutationHookOptions<SettingsBillingSourceDestroyMutation, SettingsBillingSourceDestroyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettingsBillingSourceDestroyMutation, SettingsBillingSourceDestroyMutationVariables>(SettingsBillingSourceDestroyDocument, options);
      }
export type SettingsBillingSourceDestroyMutationHookResult = ReturnType<typeof useSettingsBillingSourceDestroyMutation>;
export type SettingsBillingSourceDestroyMutationResult = Apollo.MutationResult<SettingsBillingSourceDestroyMutation>;
export type SettingsBillingSourceDestroyMutationOptions = Apollo.BaseMutationOptions<SettingsBillingSourceDestroyMutation, SettingsBillingSourceDestroyMutationVariables>;
export const DestroyNotificationDocument = gql`
    mutation DestroyNotification($id: ID!) {
  result: destroyNotification(id: $id) {
    notification {
      id
      deleted
    }
  }
}
    `;
export type DestroyNotificationMutationFn = Apollo.MutationFunction<DestroyNotificationMutation, DestroyNotificationMutationVariables>;

/**
 * __useDestroyNotificationMutation__
 *
 * To run a mutation, you first call `useDestroyNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyNotificationMutation, { data, loading, error }] = useDestroyNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDestroyNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DestroyNotificationMutation, DestroyNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyNotificationMutation, DestroyNotificationMutationVariables>(DestroyNotificationDocument, options);
      }
export type DestroyNotificationMutationHookResult = ReturnType<typeof useDestroyNotificationMutation>;
export type DestroyNotificationMutationResult = Apollo.MutationResult<DestroyNotificationMutation>;
export type DestroyNotificationMutationOptions = Apollo.BaseMutationOptions<DestroyNotificationMutation, DestroyNotificationMutationVariables>;
export const FollowUserDocument = gql`
    mutation FollowUser($id: ID!) {
  result: followUser(id: $id) {
    user {
      id
      ...follow
    }
  }
}
    ${FollowFragmentDoc}`;
export type FollowUserMutationFn = Apollo.MutationFunction<FollowUserMutation, FollowUserMutationVariables>;

/**
 * __useFollowUserMutation__
 *
 * To run a mutation, you first call `useFollowUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followUserMutation, { data, loading, error }] = useFollowUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFollowUserMutation(baseOptions?: Apollo.MutationHookOptions<FollowUserMutation, FollowUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FollowUserMutation, FollowUserMutationVariables>(FollowUserDocument, options);
      }
export type FollowUserMutationHookResult = ReturnType<typeof useFollowUserMutation>;
export type FollowUserMutationResult = Apollo.MutationResult<FollowUserMutation>;
export type FollowUserMutationOptions = Apollo.BaseMutationOptions<FollowUserMutation, FollowUserMutationVariables>;
export const LikeFeedEntryDocument = gql`
    mutation LikeFeedEntry($id: ID!) {
  result: likeFeedEntry(id: $id) {
    entry {
      id
      liked
      likes
    }
  }
}
    `;
export type LikeFeedEntryMutationFn = Apollo.MutationFunction<LikeFeedEntryMutation, LikeFeedEntryMutationVariables>;

/**
 * __useLikeFeedEntryMutation__
 *
 * To run a mutation, you first call `useLikeFeedEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeFeedEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeFeedEntryMutation, { data, loading, error }] = useLikeFeedEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLikeFeedEntryMutation(baseOptions?: Apollo.MutationHookOptions<LikeFeedEntryMutation, LikeFeedEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LikeFeedEntryMutation, LikeFeedEntryMutationVariables>(LikeFeedEntryDocument, options);
      }
export type LikeFeedEntryMutationHookResult = ReturnType<typeof useLikeFeedEntryMutation>;
export type LikeFeedEntryMutationResult = Apollo.MutationResult<LikeFeedEntryMutation>;
export type LikeFeedEntryMutationOptions = Apollo.BaseMutationOptions<LikeFeedEntryMutation, LikeFeedEntryMutationVariables>;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    status
    token
    errors {
      messages
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    status
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ReadNotificationDocument = gql`
    mutation ReadNotification($id: ID!) {
  result: readNotification(id: $id) {
    notification {
      id
      read
    }
  }
}
    `;
export type ReadNotificationMutationFn = Apollo.MutationFunction<ReadNotificationMutation, ReadNotificationMutationVariables>;

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationMutation, { data, loading, error }] = useReadNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ReadNotificationMutation, ReadNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadNotificationMutation, ReadNotificationMutationVariables>(ReadNotificationDocument, options);
      }
export type ReadNotificationMutationHookResult = ReturnType<typeof useReadNotificationMutation>;
export type ReadNotificationMutationResult = Apollo.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = Apollo.BaseMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables>;
export const SettingsAuthenticationDestroyDocument = gql`
    mutation SettingsAuthenticationDestroy($id: String!) {
  result: destroyAuthentication(id: $id) {
    authentication {
      id
      deleted
    }
  }
}
    `;
export type SettingsAuthenticationDestroyMutationFn = Apollo.MutationFunction<SettingsAuthenticationDestroyMutation, SettingsAuthenticationDestroyMutationVariables>;

/**
 * __useSettingsAuthenticationDestroyMutation__
 *
 * To run a mutation, you first call `useSettingsAuthenticationDestroyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsAuthenticationDestroyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsAuthenticationDestroyMutation, { data, loading, error }] = useSettingsAuthenticationDestroyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSettingsAuthenticationDestroyMutation(baseOptions?: Apollo.MutationHookOptions<SettingsAuthenticationDestroyMutation, SettingsAuthenticationDestroyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettingsAuthenticationDestroyMutation, SettingsAuthenticationDestroyMutationVariables>(SettingsAuthenticationDestroyDocument, options);
      }
export type SettingsAuthenticationDestroyMutationHookResult = ReturnType<typeof useSettingsAuthenticationDestroyMutation>;
export type SettingsAuthenticationDestroyMutationResult = Apollo.MutationResult<SettingsAuthenticationDestroyMutation>;
export type SettingsAuthenticationDestroyMutationOptions = Apollo.BaseMutationOptions<SettingsAuthenticationDestroyMutation, SettingsAuthenticationDestroyMutationVariables>;
export const SettingsAvatarAttachDocument = gql`
    mutation SettingsAvatarAttach($id: ID!) {
  result: attachAvatar(id: $id) {
    status
  }
}
    `;
export type SettingsAvatarAttachMutationFn = Apollo.MutationFunction<SettingsAvatarAttachMutation, SettingsAvatarAttachMutationVariables>;

/**
 * __useSettingsAvatarAttachMutation__
 *
 * To run a mutation, you first call `useSettingsAvatarAttachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsAvatarAttachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsAvatarAttachMutation, { data, loading, error }] = useSettingsAvatarAttachMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSettingsAvatarAttachMutation(baseOptions?: Apollo.MutationHookOptions<SettingsAvatarAttachMutation, SettingsAvatarAttachMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettingsAvatarAttachMutation, SettingsAvatarAttachMutationVariables>(SettingsAvatarAttachDocument, options);
      }
export type SettingsAvatarAttachMutationHookResult = ReturnType<typeof useSettingsAvatarAttachMutation>;
export type SettingsAvatarAttachMutationResult = Apollo.MutationResult<SettingsAvatarAttachMutation>;
export type SettingsAvatarAttachMutationOptions = Apollo.BaseMutationOptions<SettingsAvatarAttachMutation, SettingsAvatarAttachMutationVariables>;
export const SettingsAvatarDetachDocument = gql`
    mutation SettingsAvatarDetach {
  result: detachAvatar {
    status
  }
}
    `;
export type SettingsAvatarDetachMutationFn = Apollo.MutationFunction<SettingsAvatarDetachMutation, SettingsAvatarDetachMutationVariables>;

/**
 * __useSettingsAvatarDetachMutation__
 *
 * To run a mutation, you first call `useSettingsAvatarDetachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsAvatarDetachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsAvatarDetachMutation, { data, loading, error }] = useSettingsAvatarDetachMutation({
 *   variables: {
 *   },
 * });
 */
export function useSettingsAvatarDetachMutation(baseOptions?: Apollo.MutationHookOptions<SettingsAvatarDetachMutation, SettingsAvatarDetachMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettingsAvatarDetachMutation, SettingsAvatarDetachMutationVariables>(SettingsAvatarDetachDocument, options);
      }
export type SettingsAvatarDetachMutationHookResult = ReturnType<typeof useSettingsAvatarDetachMutation>;
export type SettingsAvatarDetachMutationResult = Apollo.MutationResult<SettingsAvatarDetachMutation>;
export type SettingsAvatarDetachMutationOptions = Apollo.BaseMutationOptions<SettingsAvatarDetachMutation, SettingsAvatarDetachMutationVariables>;
export const SettingsBillingSourceBuildDocument = gql`
    mutation SettingsBillingSourceBuild($source: String!) {
  result: buildBillingSource(source: $source) {
    billing {
      id
      customer {
        id
        sources {
          ...billing__source
        }
      }
    }
  }
}
    ${Billing__SourceFragmentDoc}`;
export type SettingsBillingSourceBuildMutationFn = Apollo.MutationFunction<SettingsBillingSourceBuildMutation, SettingsBillingSourceBuildMutationVariables>;

/**
 * __useSettingsBillingSourceBuildMutation__
 *
 * To run a mutation, you first call `useSettingsBillingSourceBuildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsBillingSourceBuildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsBillingSourceBuildMutation, { data, loading, error }] = useSettingsBillingSourceBuildMutation({
 *   variables: {
 *      source: // value for 'source'
 *   },
 * });
 */
export function useSettingsBillingSourceBuildMutation(baseOptions?: Apollo.MutationHookOptions<SettingsBillingSourceBuildMutation, SettingsBillingSourceBuildMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettingsBillingSourceBuildMutation, SettingsBillingSourceBuildMutationVariables>(SettingsBillingSourceBuildDocument, options);
      }
export type SettingsBillingSourceBuildMutationHookResult = ReturnType<typeof useSettingsBillingSourceBuildMutation>;
export type SettingsBillingSourceBuildMutationResult = Apollo.MutationResult<SettingsBillingSourceBuildMutation>;
export type SettingsBillingSourceBuildMutationOptions = Apollo.BaseMutationOptions<SettingsBillingSourceBuildMutation, SettingsBillingSourceBuildMutationVariables>;
export const SettingsBillingSourceDefaultDocument = gql`
    mutation SettingsBillingSourceDefault($id: ID!) {
  result: defaultBillingSource(id: $id) {
    billing {
      id
      customer {
        id
        sources {
          ...billing__source
        }
      }
    }
  }
}
    ${Billing__SourceFragmentDoc}`;
export type SettingsBillingSourceDefaultMutationFn = Apollo.MutationFunction<SettingsBillingSourceDefaultMutation, SettingsBillingSourceDefaultMutationVariables>;

/**
 * __useSettingsBillingSourceDefaultMutation__
 *
 * To run a mutation, you first call `useSettingsBillingSourceDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsBillingSourceDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsBillingSourceDefaultMutation, { data, loading, error }] = useSettingsBillingSourceDefaultMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSettingsBillingSourceDefaultMutation(baseOptions?: Apollo.MutationHookOptions<SettingsBillingSourceDefaultMutation, SettingsBillingSourceDefaultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettingsBillingSourceDefaultMutation, SettingsBillingSourceDefaultMutationVariables>(SettingsBillingSourceDefaultDocument, options);
      }
export type SettingsBillingSourceDefaultMutationHookResult = ReturnType<typeof useSettingsBillingSourceDefaultMutation>;
export type SettingsBillingSourceDefaultMutationResult = Apollo.MutationResult<SettingsBillingSourceDefaultMutation>;
export type SettingsBillingSourceDefaultMutationOptions = Apollo.BaseMutationOptions<SettingsBillingSourceDefaultMutation, SettingsBillingSourceDefaultMutationVariables>;
export const SettingsChangeProfileDocument = gql`
    mutation SettingsChangeProfile($input: UserInput!) {
  result: changeProfile(input: $input) {
    status
    errors {
      messages
    }
  }
}
    `;
export type SettingsChangeProfileMutationFn = Apollo.MutationFunction<SettingsChangeProfileMutation, SettingsChangeProfileMutationVariables>;

/**
 * __useSettingsChangeProfileMutation__
 *
 * To run a mutation, you first call `useSettingsChangeProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsChangeProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsChangeProfileMutation, { data, loading, error }] = useSettingsChangeProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSettingsChangeProfileMutation(baseOptions?: Apollo.MutationHookOptions<SettingsChangeProfileMutation, SettingsChangeProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettingsChangeProfileMutation, SettingsChangeProfileMutationVariables>(SettingsChangeProfileDocument, options);
      }
export type SettingsChangeProfileMutationHookResult = ReturnType<typeof useSettingsChangeProfileMutation>;
export type SettingsChangeProfileMutationResult = Apollo.MutationResult<SettingsChangeProfileMutation>;
export type SettingsChangeProfileMutationOptions = Apollo.BaseMutationOptions<SettingsChangeProfileMutation, SettingsChangeProfileMutationVariables>;
export const SettingsPasswordDocument = gql`
    mutation SettingsPassword($password: PasswordInput!) {
  result: changePassword(password: $password) {
    status
    errors {
      messages
    }
  }
}
    `;
export type SettingsPasswordMutationFn = Apollo.MutationFunction<SettingsPasswordMutation, SettingsPasswordMutationVariables>;

/**
 * __useSettingsPasswordMutation__
 *
 * To run a mutation, you first call `useSettingsPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsPasswordMutation, { data, loading, error }] = useSettingsPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSettingsPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SettingsPasswordMutation, SettingsPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettingsPasswordMutation, SettingsPasswordMutationVariables>(SettingsPasswordDocument, options);
      }
export type SettingsPasswordMutationHookResult = ReturnType<typeof useSettingsPasswordMutation>;
export type SettingsPasswordMutationResult = Apollo.MutationResult<SettingsPasswordMutation>;
export type SettingsPasswordMutationOptions = Apollo.BaseMutationOptions<SettingsPasswordMutation, SettingsPasswordMutationVariables>;
export const SignupDocument = gql`
    mutation Signup($input: SignupInput!) {
  signup(input: $input) {
    status
    token
    errors {
      messages
    }
  }
}
    `;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const UnfollowUserDocument = gql`
    mutation UnfollowUser($id: ID!) {
  result: unfollowUser(id: $id) {
    user {
      id
      ...follow
    }
  }
}
    ${FollowFragmentDoc}`;
export type UnfollowUserMutationFn = Apollo.MutationFunction<UnfollowUserMutation, UnfollowUserMutationVariables>;

/**
 * __useUnfollowUserMutation__
 *
 * To run a mutation, you first call `useUnfollowUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowUserMutation, { data, loading, error }] = useUnfollowUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnfollowUserMutation(baseOptions?: Apollo.MutationHookOptions<UnfollowUserMutation, UnfollowUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnfollowUserMutation, UnfollowUserMutationVariables>(UnfollowUserDocument, options);
      }
export type UnfollowUserMutationHookResult = ReturnType<typeof useUnfollowUserMutation>;
export type UnfollowUserMutationResult = Apollo.MutationResult<UnfollowUserMutation>;
export type UnfollowUserMutationOptions = Apollo.BaseMutationOptions<UnfollowUserMutation, UnfollowUserMutationVariables>;
export const UnlikeFeedEntryDocument = gql`
    mutation UnlikeFeedEntry($id: ID!) {
  result: unlikeFeedEntry(id: $id) {
    entry {
      id
      liked
      likes
    }
  }
}
    `;
export type UnlikeFeedEntryMutationFn = Apollo.MutationFunction<UnlikeFeedEntryMutation, UnlikeFeedEntryMutationVariables>;

/**
 * __useUnlikeFeedEntryMutation__
 *
 * To run a mutation, you first call `useUnlikeFeedEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlikeFeedEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlikeFeedEntryMutation, { data, loading, error }] = useUnlikeFeedEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnlikeFeedEntryMutation(baseOptions?: Apollo.MutationHookOptions<UnlikeFeedEntryMutation, UnlikeFeedEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlikeFeedEntryMutation, UnlikeFeedEntryMutationVariables>(UnlikeFeedEntryDocument, options);
      }
export type UnlikeFeedEntryMutationHookResult = ReturnType<typeof useUnlikeFeedEntryMutation>;
export type UnlikeFeedEntryMutationResult = Apollo.MutationResult<UnlikeFeedEntryMutation>;
export type UnlikeFeedEntryMutationOptions = Apollo.BaseMutationOptions<UnlikeFeedEntryMutation, UnlikeFeedEntryMutationVariables>;
export const FeedDocument = gql`
    query Feed {
  feed {
    id
    entries {
      ...feed__entry
    }
  }
}
    ${Feed__EntryFragmentDoc}`;

/**
 * __useFeedQuery__
 *
 * To run a query within a React component, call `useFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeedQuery(baseOptions?: Apollo.QueryHookOptions<FeedQuery, FeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedQuery, FeedQueryVariables>(FeedDocument, options);
      }
export function useFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedQuery, FeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedQuery, FeedQueryVariables>(FeedDocument, options);
        }
export function useFeedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FeedQuery, FeedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FeedQuery, FeedQueryVariables>(FeedDocument, options);
        }
export type FeedQueryHookResult = ReturnType<typeof useFeedQuery>;
export type FeedLazyQueryHookResult = ReturnType<typeof useFeedLazyQuery>;
export type FeedSuspenseQueryHookResult = ReturnType<typeof useFeedSuspenseQuery>;
export type FeedQueryResult = Apollo.QueryResult<FeedQuery, FeedQueryVariables>;
export const FeedCommentsDocument = gql`
    query FeedComments($id: ID!) {
  feed {
    id
    entry(id: $id) {
      id
      comments {
        ...feed__comment
      }
    }
  }
}
    ${Feed__CommentFragmentDoc}`;

/**
 * __useFeedCommentsQuery__
 *
 * To run a query within a React component, call `useFeedCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFeedCommentsQuery(baseOptions: Apollo.QueryHookOptions<FeedCommentsQuery, FeedCommentsQueryVariables> & ({ variables: FeedCommentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedCommentsQuery, FeedCommentsQueryVariables>(FeedCommentsDocument, options);
      }
export function useFeedCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedCommentsQuery, FeedCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedCommentsQuery, FeedCommentsQueryVariables>(FeedCommentsDocument, options);
        }
export function useFeedCommentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FeedCommentsQuery, FeedCommentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FeedCommentsQuery, FeedCommentsQueryVariables>(FeedCommentsDocument, options);
        }
export type FeedCommentsQueryHookResult = ReturnType<typeof useFeedCommentsQuery>;
export type FeedCommentsLazyQueryHookResult = ReturnType<typeof useFeedCommentsLazyQuery>;
export type FeedCommentsSuspenseQueryHookResult = ReturnType<typeof useFeedCommentsSuspenseQuery>;
export type FeedCommentsQueryResult = Apollo.QueryResult<FeedCommentsQuery, FeedCommentsQueryVariables>;
export const FeedEntryDocument = gql`
    query FeedEntry($id: ID!) {
  feed {
    id
    entry(id: $id) {
      ...feed__entry
    }
  }
}
    ${Feed__EntryFragmentDoc}`;

/**
 * __useFeedEntryQuery__
 *
 * To run a query within a React component, call `useFeedEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedEntryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFeedEntryQuery(baseOptions: Apollo.QueryHookOptions<FeedEntryQuery, FeedEntryQueryVariables> & ({ variables: FeedEntryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedEntryQuery, FeedEntryQueryVariables>(FeedEntryDocument, options);
      }
export function useFeedEntryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedEntryQuery, FeedEntryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedEntryQuery, FeedEntryQueryVariables>(FeedEntryDocument, options);
        }
export function useFeedEntrySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FeedEntryQuery, FeedEntryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FeedEntryQuery, FeedEntryQueryVariables>(FeedEntryDocument, options);
        }
export type FeedEntryQueryHookResult = ReturnType<typeof useFeedEntryQuery>;
export type FeedEntryLazyQueryHookResult = ReturnType<typeof useFeedEntryLazyQuery>;
export type FeedEntrySuspenseQueryHookResult = ReturnType<typeof useFeedEntrySuspenseQuery>;
export type FeedEntryQueryResult = Apollo.QueryResult<FeedEntryQuery, FeedEntryQueryVariables>;
export const NotificationsDocument = gql`
    query Notifications {
  notifications {
    ...notification
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export function useNotificationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsSuspenseQueryHookResult = ReturnType<typeof useNotificationsSuspenseQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const ProfileDocument = gql`
    query Profile($id: ID!) {
  user(id: $id) {
    ...user
    ...follow
    feed {
      entries {
        ...feed__entry
      }
    }
  }
}
    ${UserFragmentDoc}
${FollowFragmentDoc}
${Feed__EntryFragmentDoc}`;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfileQuery(baseOptions: Apollo.QueryHookOptions<ProfileQuery, ProfileQueryVariables> & ({ variables: ProfileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
      }
export function useProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
        }
export function useProfileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
        }
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileSuspenseQueryHookResult = ReturnType<typeof useProfileSuspenseQuery>;
export type ProfileQueryResult = Apollo.QueryResult<ProfileQuery, ProfileQueryVariables>;
export const SettingsAuthenticationsDocument = gql`
    query SettingsAuthentications {
  authentications {
    ...authentication
  }
}
    ${AuthenticationFragmentDoc}`;

/**
 * __useSettingsAuthenticationsQuery__
 *
 * To run a query within a React component, call `useSettingsAuthenticationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsAuthenticationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsAuthenticationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsAuthenticationsQuery(baseOptions?: Apollo.QueryHookOptions<SettingsAuthenticationsQuery, SettingsAuthenticationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingsAuthenticationsQuery, SettingsAuthenticationsQueryVariables>(SettingsAuthenticationsDocument, options);
      }
export function useSettingsAuthenticationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsAuthenticationsQuery, SettingsAuthenticationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingsAuthenticationsQuery, SettingsAuthenticationsQueryVariables>(SettingsAuthenticationsDocument, options);
        }
export function useSettingsAuthenticationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SettingsAuthenticationsQuery, SettingsAuthenticationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SettingsAuthenticationsQuery, SettingsAuthenticationsQueryVariables>(SettingsAuthenticationsDocument, options);
        }
export type SettingsAuthenticationsQueryHookResult = ReturnType<typeof useSettingsAuthenticationsQuery>;
export type SettingsAuthenticationsLazyQueryHookResult = ReturnType<typeof useSettingsAuthenticationsLazyQuery>;
export type SettingsAuthenticationsSuspenseQueryHookResult = ReturnType<typeof useSettingsAuthenticationsSuspenseQuery>;
export type SettingsAuthenticationsQueryResult = Apollo.QueryResult<SettingsAuthenticationsQuery, SettingsAuthenticationsQueryVariables>;
export const SettingsAvatarDocument = gql`
    query SettingsAvatar {
  me {
    id
    name
    avatar {
      ...attached
    }
  }
}
    ${AttachedFragmentDoc}`;

/**
 * __useSettingsAvatarQuery__
 *
 * To run a query within a React component, call `useSettingsAvatarQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsAvatarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsAvatarQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsAvatarQuery(baseOptions?: Apollo.QueryHookOptions<SettingsAvatarQuery, SettingsAvatarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingsAvatarQuery, SettingsAvatarQueryVariables>(SettingsAvatarDocument, options);
      }
export function useSettingsAvatarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsAvatarQuery, SettingsAvatarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingsAvatarQuery, SettingsAvatarQueryVariables>(SettingsAvatarDocument, options);
        }
export function useSettingsAvatarSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SettingsAvatarQuery, SettingsAvatarQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SettingsAvatarQuery, SettingsAvatarQueryVariables>(SettingsAvatarDocument, options);
        }
export type SettingsAvatarQueryHookResult = ReturnType<typeof useSettingsAvatarQuery>;
export type SettingsAvatarLazyQueryHookResult = ReturnType<typeof useSettingsAvatarLazyQuery>;
export type SettingsAvatarSuspenseQueryHookResult = ReturnType<typeof useSettingsAvatarSuspenseQuery>;
export type SettingsAvatarQueryResult = Apollo.QueryResult<SettingsAvatarQuery, SettingsAvatarQueryVariables>;
export const SettingsBillingDocument = gql`
    query SettingsBilling {
  billing {
    id
    customer {
      id
      currency
      sources {
        ...billing__source
      }
    }
  }
}
    ${Billing__SourceFragmentDoc}`;

/**
 * __useSettingsBillingQuery__
 *
 * To run a query within a React component, call `useSettingsBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsBillingQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsBillingQuery(baseOptions?: Apollo.QueryHookOptions<SettingsBillingQuery, SettingsBillingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingsBillingQuery, SettingsBillingQueryVariables>(SettingsBillingDocument, options);
      }
export function useSettingsBillingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsBillingQuery, SettingsBillingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingsBillingQuery, SettingsBillingQueryVariables>(SettingsBillingDocument, options);
        }
export function useSettingsBillingSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SettingsBillingQuery, SettingsBillingQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SettingsBillingQuery, SettingsBillingQueryVariables>(SettingsBillingDocument, options);
        }
export type SettingsBillingQueryHookResult = ReturnType<typeof useSettingsBillingQuery>;
export type SettingsBillingLazyQueryHookResult = ReturnType<typeof useSettingsBillingLazyQuery>;
export type SettingsBillingSuspenseQueryHookResult = ReturnType<typeof useSettingsBillingSuspenseQuery>;
export type SettingsBillingQueryResult = Apollo.QueryResult<SettingsBillingQuery, SettingsBillingQueryVariables>;
export const SettingsProfileDocument = gql`
    query SettingsProfile {
  me {
    id
    name
    email
  }
}
    `;

/**
 * __useSettingsProfileQuery__
 *
 * To run a query within a React component, call `useSettingsProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsProfileQuery(baseOptions?: Apollo.QueryHookOptions<SettingsProfileQuery, SettingsProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingsProfileQuery, SettingsProfileQueryVariables>(SettingsProfileDocument, options);
      }
export function useSettingsProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsProfileQuery, SettingsProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingsProfileQuery, SettingsProfileQueryVariables>(SettingsProfileDocument, options);
        }
export function useSettingsProfileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SettingsProfileQuery, SettingsProfileQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SettingsProfileQuery, SettingsProfileQueryVariables>(SettingsProfileDocument, options);
        }
export type SettingsProfileQueryHookResult = ReturnType<typeof useSettingsProfileQuery>;
export type SettingsProfileLazyQueryHookResult = ReturnType<typeof useSettingsProfileLazyQuery>;
export type SettingsProfileSuspenseQueryHookResult = ReturnType<typeof useSettingsProfileSuspenseQuery>;
export type SettingsProfileQueryResult = Apollo.QueryResult<SettingsProfileQuery, SettingsProfileQueryVariables>;